import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import dayjs from 'dayjs';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import DatePicker from "react-datepicker";

// import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

const StoreDetailSmoke = (props: any) => {
  // const [startDate, setStartDate] = useState<Date | null>(new Date());
  // const [formatedTime, setTime] = useState<any>(new Date());
  let getLogo = () => {
    let mediaUrl = props?.merchantDetails?.logo || "/assets/allstore/img1.png";
    return mediaUrl;
  };
  return (
    <div className="smokeshowstudio">
      <div className="upperrow">
        <div className="leftsidesmoke">
          <div className="outmaininner">
            <div className="leftsidemaininner">
              {props?.opens ? (
                <Skeleton variant="rectangular" width={120} height={120} />
              ) : (
                <img
                  src={getLogo()}
                  alt="cnctwltimg"
                  className="sasdasdasdzxewfsac"
                />
              )}
            </div>
            <div className="rightsidemaininner">
              <div className="cardssiner" style={{ gap: "30px" }}>
                {props?.merchantDetails?.specialization && (
                  <div className="innerrs">
                    <h5>{props.merchantDetails?.specialization}</h5>
                  </div>
                )}

                {/* <div className='innerrss'>
                                    {props.merchantDetails?.isOpen ? (
                                        <h5>Open</h5>
                                    ) : (
                                        <span> </span>
                                    )}
                                </div> */}
                {/* <div className='innerrsss'>
                                    <h5>Female</h5>
                                </div> */}
                {props?.merchantDetails?.hasOwnProperty("isOpen") && (
                  <Box display="flex" alignItems="center" gap="5px">
                    <img
                      src="\assets\ri_time-line.svg"
                      alt="cnctwltimg"
                      className="cnctwltimsdchjudsbcg"
                    />
                    <Box
                      component="h6"
                      sx={{
                        color: "rgba(0, 0, 0, 0.6)",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "17px",
                      }}
                    >
                      {props?.merchantDetails?.isOpen ? "Open Today" : "Closed"}
                    </Box>
                  </Box>
                )}
              </div>
              <h3>{props.merchantDetails?.merchant_name}</h3>
              <div className="ratings">
                {/* <div className='upperright'>
                                    <div className="adasdasa">
                                        <div className="ratings">
                                            <i className="fa fa-star rating-color"></i>
                                        </div>
                                    </div>
                                    <div className='text'>
                                        <h4>4.7</h4>
                                        <h5>(4.2k)</h5>
                                    </div>
                                </div> */}
                <div className="downright">
                  {/* <img src="\assets\storeimage\img10.svg" alt="cnctwltimg" className="storeimageleft" /> */}
                  <h6>
                    {" "}
                    <Link
                      className="textssss"
                      to={{
                        pathname: `${props?.merchantDetails?.merchant_website}`,
                      }}
                      target="_blank"
                    >
                      {props?.merchantDetails?.merchant_website}
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightsidesmoke">
          <img
            src={props.merchantDetails?.mediaurl}
            alt="cnctwltimg"
            className="storeimsdfdsfsdfageleft"
          />
          <h6>
            Owner <span>{props.merchantDetails?.OwnerName}</span>
          </h6>
        </div>
      </div>

      {props?.merchantDetails?.periods?.length > 0 && (
        <Box maxWidth="98%" m="auto">
          <Box display="flex" alignItems="center" gap="5px" mb={2}>
            <img
              src="\assets\ri_time-line.svg"
              alt="cnctwltimg"
              className="cnctwltimsdchjudsbcg"
            />
            <Box
              component="h6"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "17px",
              }}
            >
              Opening Timings{" "}
              {props?.merchantDetails?.timezone
                ? `(${props?.merchantDetails?.timezone})`
                : ""}
            </Box>
          </Box>
          <Grid container>
            {props?.merchantDetails?.periods?.map(
              (dayItem: any, index: number) => (
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center" gap="20px">
                    <Box>
                      <Box
                        sx={{
                          width: 10,
                          height: 10,
                          backgroundColor: "#4b2763",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" gap="10px">
                      <Box
                        component="h6"
                        sx={{
                          color: "rgba(0, 0, 0, 0.6)",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "17px",
                        }}
                      >
                        {dayItem?.period === "Mon/Wed/Fri/Sat/Sun" ||
                        dayItem?.period === "All/days"
                          ? "All Days"
                          : dayItem?.period}
                      </Box>
                      <Box
                        component="h6"
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "17px",
                        }}
                      >
                        {dayItem?.start} - {dayItem?.end}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      )}

      <div className="downrow">
        {/* <div className="alltimess">
          <img
            src="\assets\storeimage\img14.svg"
            alt="cnctwltimg"
            className="storeimageleft"
          />
          <h6>30 Specialists</h6>
        </div>
        <div className="alltimess">
          <img
            src="\assets\storeimage\img13.svg"
            alt="cnctwltimg"
            className="storeimageleft"
          />
          <h6>30 Services</h6>
        </div>
        <div className="alltimess">
          <img
            src="\assets\storeimage\img12.svg"
            alt="cnctwltimg"
            className="storeimageleft"
          />
          <h6>05 Days a Week</h6>
        </div>
        <div className="alltimess">
          <img
            src="\assets\storeimage\img11.svg"
            alt="cnctwltimg"
            className="storeimageleft"
          />
          <h6>Morning/Evening/Night</h6>
        </div> */}
        {/* <div className='mainoutermasin'>
                    <div className='left-side-main'>
                        <div className='inner-11 ooooo'>
                            <DatePicker selected={startDate} onChange={(date: Date | null) => setStartDate(date)} />
                            <img src="\assets\majesticons_calendar.svg" alt="cnctwltimg" className="cnctwltimg" />
                        </div>
                        <div className='inner-22 ooooo'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={[
                                        'TimePicker',
                                        'MobileTimePicker',
                                        'DesktopTimePicker',
                                        'StaticTimePicker',
                                    ]}
                                >
                                    <DemoItem>
                                        <MobileTimePicker orientation="landscape"
                                            defaultValue={dayjs(formatedTime)}
                                            onChange={(time: any) => setTime(time.$H + ":" + time.$m)}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                            <img src="\assets\ri_time-line.svg" alt="cnctwltimg" className="cnctwltimsdchjudsbcg" />
                        </div>
                    </div>
                    <div className='buttonfind'>
                        <button>Search</button>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default StoreDetailSmoke;
